import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {WechatPayInfoService} from "../wechat-pay-info.service";
import {PayInfo} from "../../pay-info";

@Component({
    selector: "svsp-wechat-submch-pay-info",
    templateUrl: "./sub-mch.component.html",
    styleUrls: ["./sub-mch.component.less"]
})
export class SubMchComponent implements OnInit {
    private _payInfo: PayInfo;
    validateForm: FormGroup;

    private validateTimer;
    private skipValidate = true;
    private _disabled = false;

    loading = true;
    providerList: any[];

    @Input()
    providerPayInfo: boolean;

    @Output()
    payInfoChange = new EventEmitter();

    @Input()
    get payInfo(): PayInfo {
        return this._payInfo;
    }

    @Output()
    payInfoValidChange = new EventEmitter();

    @Input()
    set disabled(value) {
        this._disabled = value;
        this.setFormDisableStatus();
    }

    private setFormDisableStatus() {
        if (this.validateForm) {
            for (const control of Object.values(this.validateForm.controls)) {
                if (this._disabled) {
                    control.disable();
                } else {
                    control.enable();
                }
            }
        }
    }

    set payInfo(payInfo) {
        if (payInfo) {
            this._payInfo = payInfo;
            if (this.validateForm) {
                this.validateForm.setValue({
                    wechatSubAppId: this._payInfo.wechatSubAppId || "",
                    wechatSubMchId: this._payInfo.wechatSubMchId || "",
                    wechatProviderId: this._payInfo.wechatProviderId || ""
                });
            }
            if (!this.skipValidate) {
                this.payInfoChange.emit(this._payInfo);
            }
            this.skipValidate = false;
        }
    }

    formChanged() {
        this.payInfo = {...this.payInfo, ...this.validateForm.getRawValue()};
    }

    providerIdChange(value: number) {
        if (this.payInfo.wechatProviderId !== value) {
            this.formChanged();
        }
    }

    inputInvalid(formControlName: string) {
        clearTimeout(this.validateTimer);
        if (!this.skipValidate) {
            this.validateTimer = setTimeout(() => {
                this.payInfoValidChange.emit(this.validateForm.valid);
            }, 50);
        }
        return (this.validateForm.get(formControlName).dirty && this.validateForm.get(formControlName).errors);
    }

    constructor(private fb: FormBuilder, private service: WechatPayInfoService) {
    }

    ngOnInit() {
        this.validateForm = this.fb.group({
            wechatSubAppId: [null],
            wechatSubMchId: [null, [Validators.required]],
            wechatProviderId: [null, [Validators.required]]
        });
        this.setFormDisableStatus();
        this.providerPayInfo = this.providerPayInfo === true;
        this.payInfo = this._payInfo;
        this.service.getProviderList({payPlatform: "2"})
            .subscribe(res => {
                this.providerList = res.dataSet;
                this.loading = false;
            });
    }
}
