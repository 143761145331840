import {Route} from "@angular/router";

export const channelTemplateRoute: Route = {
    path: "channel-template",
    loadChildren: () => import("./channel-template.module").then(m => m.ChannelTemplateModule),
    data: {
        menuParent: "setting",
        menuLabel: "货道模板",
    }
};
