import {Route} from "@angular/router";

export const userRoute: Route = {
    path: "user",
    loadChildren: () => import("./user.module").then(m => m.UserModule),
    data: {
        menuParent: "organization",
        menuLabel: "用户管理",
    }
};
