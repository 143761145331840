import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SERVER_API_PREFIX} from "@/app.constants";

@Injectable({
    providedIn: "root",
})
export class RefundOrderService {
    constructor(private http: HttpClient) {
    }

    query(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/refundOrder/list`, {
            params,
        });
    }

    orderDetail(orderId: String): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/refundOrder/detail/${orderId}`);
    }

    export(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/refundOrder/export`, {params});
    }

    exportOrderGoods(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/refundOrder/export/orderGoods`, {params});
    }

    dynamicQuery(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/refundOrder/hibianli/list`, {
            params,
        });
    }

    dynamicOrderDetail(orderId: String): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/refundOrder/hibianli/detail/${orderId}`);
    }
}
