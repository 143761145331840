import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from "@angular/router";
import {UserInfoService} from "../user-info.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {MainComponent} from "@/pages/public/main/main.component";
import {LoginComponent} from "../login/login.component";
import {environment} from "../../../environments/environment";
import {LoadingMask} from "../loading-mask";

@Injectable({providedIn: "root"})
export class InitUserGuard implements CanActivate {
    constructor(private privilegeService: UserInfoService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (route.component === MainComponent) {
            return this.privilegeService.initUserBaseInfo();
        } else if (route.component === LoginComponent && UserInfoService.userInfoInitialized) {
            if (environment.production === false) {
                console.log("用户信息已初始化，不允许跳转至登录页，请先登出！");
            }
            return false;
        }
        LoadingMask.remove();
        return true;
    }
}
