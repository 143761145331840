export const SERVER_API_PREFIX = "/api";
export const PICTURE_PATH = "http://";
export const MACHINE_DEVICE_PICTURE_SIZE = "?imageView2/5/h/200/w/200";
export const PICTURE_TAILORING_PATH_LAST = "?imageView2/5/h/100/w/100";
export const HORIZONTAL_PICTURE_TAILORING_PATH_LAST = "?imageView2/5/h/150/w/200";
export const ORGANIZATION_PICTURE_TAILORING_PATH_LAST = "?imageView2/5/h/150/w/200";
export const VERTICAL_PICTURE_TAILORING_PATH_LAST = "?imageView2/5/h/150/w/100";
export const PICTURE_LIST_TAILORING_PATH_LAST = "?imageView2/5/h/60/w/60";
export const TINY_ATTACHMENT_UPLOAD_ADDRESS = `${SERVER_API_PREFIX}/file-upload/tiny-attachment`;
export const WECHAT_DOMAIN_VERIFY_FILE_UPLOAD_ADDRESS = `${SERVER_API_PREFIX}/attachment/wechatDomainVerifyFile`;
export const BMAP_API_AK = "3HGwrVdkLA77QulrokSnY6ZxwVCcod2h";
export const REPORT_PATH = "/resource/svsp/report/";
export const PAY_PLATFORM_MAP = {
    "0": "现金",
    "1": "支付宝",
    "2": "微信",
    "3": "银联",
    "4": "京东"
};
export const PAY_APP_MAP = {
    "0": "现金",
    "1": "支付宝APP",
    "2": "微信APP",
    "3": "银联APP",
    "4": "京东APP",
    "5": "京东金融APP"
};
export const PAY_SCENE_MAP = {
    "cash": "现金",
    "native_qr": "原生二维码",
    "integrated_qr": "聚合二维码",
    "bar_code": "支付宝条码",
    "wave_code": "支付宝声波",
    "security_code": "支付宝扫脸",
    "ali_mini": "支付宝小程序",
    "mircro_pay": "微信条码",
    "face_pay": "微信扫脸",
    "wechat_mini": "微信小程序",
    "union_face": "银联扫脸"
};
export const ORDER_STATUS_MAP = {
    "0": "已创建",
    "1": "未支付",
    "2": "已支付",
    "3": "已退款",
    "9": "已关闭"
};
export const ORDER_STATUS_FLOW_MAP = {
    "0": "创建订单",
    "1": "等待支付",
    "2": "订单支付",
    "3": "订单退款",
    "9": "订单关闭",
};
export const SHIPPING_STATUS_MAP = {
    "0": "未出货",
    "1": "出货成功",
    "2": "出货失败",
};
export const refund_SOURCE_MAP = {
    "1": "终端退款",
    "2": "管理员退款",
    "3": "系统退款",
};
export const REFUND_STATUS = {
    "0": "(全部)",
    "1": "(部分)"
};
export const NOT_SELLABLED = {
    "0": "允许售卖",
    "1": "禁止售卖"
};
export const DEVICE_STATE = {
    "0": "待激活",
    "1": "在库",
    "2": "运营中"
};
export const NETWORK_TYPE = {
    "4G": "4G",
    "5G": "5G",
    "WIFI": "WIFI"
};
export const BIND_STATE = {
    "0": "未绑定",
    "1": "已绑定"
};
export const MACHINE_TYPE = {
    "0": "饮料机",
    "1": "格子机",
    "2": "弹簧机"
};
export const FIRMWARE_TYPE = {
    "VENDING_APP": "售卖APP",
    "VENDING_DRIVER": "售货机驱动",
    "CAMERA_DRIVER": "摄像头驱动",
};
export const INDUSTRIAL_CONTROL_LOG_STATUS = {
    "FAILED": "失败",
    "FINISHED": "完成",
    "CREATED": "创建中"
};
export const ORG_SELECT_PAY_INFO_SCENE_MAP = {
    "10": "支付宝-原生码",
    "11": "支付宝-聚合码",
    "12": "支付宝-反扫",
    "13": "支付宝-声波",
    "14": "支付宝-扫脸",
    "15": "支付宝-刷脸信用分",
    "20": "微信-原生码",
    "21": "微信-聚合码",
    "24": "微信-扫脸",
    "25": "微信-扫脸支付分"
};
export const ORDER_HI_FLOW = {
    "0": "开门",
    "1": "关门 - 待结算",
    "2": "开门 - 未拉门",
    "3": "关门 - 未取货",
    "4": "关门 - 未支付",
    "5": "关门 - 支付中",
    "6": "关门 - 已支付"
};
export const DYNAMIC_PAY_SCENE_MAP = {
    "ali_auth_pay_face": "刷脸信用分",
    "ali_auth_pay_qr": "扫码信用分",
    "ali_auth_pay_mini_qr": "扫码信用分",
    "ali_auth_pay_fixed_qr": "固定码信用分",
    "ali_auth_pay_mini_fixed_qr": "固定码信用分",
    "wechat_pay_score_face": "刷脸支付分",
    "wechat_pay_score_qr": "扫码支付分",
    "wechat_pay_score_mini_qr": "扫码支付分",
    "wechat_pay_score_fixed_qr": "固定码H5支付分",
    "wechat_pay_score_mini_fixed_qr": "固定码小程序支付分"
};
export const ORDER_TYPE = {
    "1": "异常订单"
};
