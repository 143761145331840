import {Route} from "@angular/router";

export const publicGoodsRoute: Route = {
    path: "public-goods",
    loadChildren: () => import("./public-goods.module").then(m => m.PublicGoodsModule),
    data: {
        menuParent: "systemLibrary",
        menuLabel: "公共商品",
    }
};
