import {Route} from "@angular/router";

export const orderPaymentLogLogRoute: Route = {
    path: "order-payment-log",
    loadChildren: () => import("./order-payment-log.module").then(m => m.OrderPaymentLogModule),
    data: {
        menuParent: "logs",
        menuLabel: "支付平台日志",
    },
};
