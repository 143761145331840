import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PayInfo} from "../pay-info";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: "svsp-alipay-pay-info",
    templateUrl: "./alipay-pay-info.component.html",
    styleUrls: ["./alipay-pay-info.component.less"],
})
export class AlipayPayInfoComponent implements OnInit {
    private _payInfo: PayInfo;
    validateForm: FormGroup;

    private validateTimer;
    private skipValidate = true;
    private _disabled = false;

    @Output()
    payInfoChange = new EventEmitter();

    @Output()
    payInfoValidChange = new EventEmitter();

    @Input()
    providerPayInfo: boolean;

    @Input()
    get payInfo(): PayInfo {
        return this._payInfo;
    }

    @Input()
    set disabled(value) {
        this._disabled = value;
        this.setFormDisableStatus();
    }

    private setFormDisableStatus() {
        if (this.validateForm) {
            for (const control of Object.values(this.validateForm.controls)) {
                if (this._disabled) {
                    control.disable();
                } else {
                    control.enable();
                }
            }
        }
    }

    set payInfo(payInfo) {
        if (payInfo) {
            this._payInfo = payInfo;
            if (this.validateForm) {
                this.validateForm.setValue({
                    payInfoId: this._payInfo.payInfoId || "",
                    createUserId: this._payInfo.createUserId || "",
                    alipayPid: this._payInfo.alipayPid || "",
                    alipayAppId: this._payInfo.alipayAppId || "",
                    alipaySellerId: this._payInfo.alipaySellerId || "",
                    alipayRsaPublicKey: this._payInfo.alipayRsaPublicKey || "",
                    alipayRsaPrivateKey: this._payInfo.alipayRsaPrivateKey || "",
                });
            }
            if (!this.skipValidate) {
                this.payInfoChange.emit(this._payInfo);
            }
            this.skipValidate = false;
        }
    }

    formChanged() {
        this.payInfo = {...this.payInfo, ...this.validateForm.getRawValue()};
    }

    inputInvalid(formControlName: string) {
        clearTimeout(this.validateTimer);
        if (!this.skipValidate) {
            this.validateTimer = setTimeout(() => {
                this.payInfoValidChange.emit(this.validateForm.valid);
            }, 50);
        }
        return (this.validateForm.get(formControlName).dirty && this.validateForm.get(formControlName).errors);
    }

    privateKeyChange() {
        if (this.validateForm.get("alipayRsaPrivateKey").value !== null) {
            this.validateForm.get("alipayRsaPrivateKey").setValue(
                this.validateForm.get("alipayRsaPrivateKey").value
                    .replace(/(^[-]+[A-Z\s]+[-]+\n)|(\n[-]+[A-Z\s]+[-]+$)|(\s?\r?\n?)/g, ""),
            );
            this.formChanged();
        }
    }

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        this.validateForm = this.fb.group({
            payInfoId: [""],
            createUserId: [""],
            alipayPid: [""],
            alipayAppId: ["", [Validators.required]],
            alipaySellerId: [""],
            alipayRsaPublicKey: ["", [Validators.required]],
            alipayRsaPrivateKey: ["", [Validators.required]],
        });
        this.providerPayInfo = this.providerPayInfo === true;
        this.setFormDisableStatus();
        this.payInfo = this._payInfo;
    }

}
