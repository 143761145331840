import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {Observable, Subscriber} from "rxjs";
import {SERVER_API_PREFIX} from "@/app.constants";
import {UserBaseInfo} from "./user-base-info";
import {LoadingMask} from "./loading-mask";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {Router} from "@angular/router";
import {GlobalSelectedOrganization, Update} from "@/global/store/global-selected-organization";
import {Store} from "@ngxs/store";

@Injectable({
    providedIn: "root",
})
export class UserInfoService {
    private static privilegeCodeList: Set<string> = new Set();
    private static lastUrl: string;
    private static _userBaseInfo: UserBaseInfo;
    private static _userInfoInitialized = false;

    constructor(private router: Router, private http: HttpClient, private store: Store) {
    }

    static get userBaseInfo(): UserBaseInfo {
        return JSON.parse(JSON.stringify(this._userBaseInfo || {})) || {};
    }

    static get userInfoInitialized(): boolean {
        return this._userInfoInitialized;
    }

    static hasPrivilege(privilegeCode: string) {
        if (!privilegeCode) {
            return true;
        } else if (environment.production === false) {
            if (!this._userBaseInfo) {
                return true;
            }
            // return privilegeCode.indexOf(this._userBaseInfo.site) === 0;
        }
        return UserInfoService.privilegeCodeList.has(privilegeCode);
    }

    private static setUserBaseInfo(userInfo: UserBaseInfo) {
        this._userBaseInfo = userInfo;
        this.privilegeCodeList.clear();
        if (userInfo.permissionList) {
            for (const code of [...userInfo.permissionList]) {
                this.privilegeCodeList.add(code);
            }
        }
        this._userInfoInitialized = true;
    }

    static clearUserBaseInfo() {
        this._userBaseInfo = undefined;
        this.privilegeCodeList.clear();
        this._userInfoInitialized = false;
    }

    static saveLastUrl(url: string) {
        UserInfoService.lastUrl = url;
    }

    initUserBaseInfo(): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            this.http
                .withoutOrganizationHeader()
                .get(`${SERVER_API_PREFIX}/common/auth/userInfo`)
                .subscribe((res: UserBaseInfo) => {
                    UserInfoService.setUserBaseInfo(res);
                    if (environment.production === false) {
                        console.log(`user base info已获取并设置：${JSON.stringify(res)}`);
                    }
                    LoadingMask.remove();
                    this.store.select(GlobalSelectedOrganization.currentSelected)
                        .subscribe(result => {
                            if (result) {
                                subscriber.next(true);
                            } else {
                                this.store.dispatch(new Update([{
                                    organizationLevel: res.organizationLevel,
                                    organizationId: res.organizationId,
                                    organizationName: res.organizationName,
                                }])).subscribe(next => {
                                    subscriber.next(true);
                                });
                            }
                        });
                }, err => {
                    if (environment.production === false) {
                        console.warn("初始化用户信息失败，开发模式下允许跳转至主页");
                        LoadingMask.remove();
                        subscriber.next(true);
                    } else {
                        this.router.navigate(["/login"]).then(() => {
                        });
                        subscriber.next(false);
                    }
                });
        });
    }

    getMinimumPasswordScore(): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/user/minimumPasswordScore`);
    }

    getPasswordScore(params): Observable<any> {
        return this.http.patch(`${SERVER_API_PREFIX}/user/passwordScore`, params);
    }

    modifyPassword(params): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/user/update/password`, params);
    }

    logout(): Observable<any> {
        return this.http.withoutOrganizationHeader().delete(`${SERVER_API_PREFIX}/common/auth/logout`);
    }

    jumpToLastUrl() {
        if (!UserInfoService.lastUrl || UserInfoService.lastUrl === "/login") {
            this.router.navigate(["/console"]);
        } else {
            this.router.navigate([UserInfoService.lastUrl]);
        }
    }
}
