import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {UserInfoService} from "../user-info.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {Store} from "@ngxs/store";

@Injectable({providedIn: "root"})
export class PrivilegeGuard implements CanActivateChild {
    constructor(private router: Router, private modalService: NzModalService, private store: Store) {
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.modalService.openModals.length > 0) {
            return false;
        }

        if (!UserInfoService.hasPrivilege(childRoute.data.requiredPrivilege)) {
            this.router.navigate(["/"]);
            return false;
        }
        return true;
    }
}
