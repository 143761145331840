import {Component, OnInit} from "@angular/core";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {RealTimeOrderService} from "../real-time-order.service";
import {
    ORDER_STATUS_FLOW_MAP,
    ORDER_STATUS_MAP,
    PAY_PLATFORM_MAP,
    PAY_SCENE_MAP,
    refund_SOURCE_MAP,
    SHIPPING_STATUS_MAP,
    ORDER_HI_FLOW, ORG_SELECT_PAY_INFO_SCENE_MAP
} from "@/app.constants";
import {RefundOrderService} from "@/pages/order/refund-order/refund-order.service";
import {HistoryOrderService} from "@/pages/order/history-order/history-order.service";
import {differenceInCalendarDays, differenceInHours, differenceInMinutes} from "date-fns";
import {DisabledTimeFn} from "ng-zorro-antd/date-picker";

@Component({
    selector: "app-order-detail",
    templateUrl: "./order-detail.component.html",
    styleUrls: ["./order-detail.component.less"],
})
export class OrderDetailComponent implements OnInit {
    orderData: any = {};
    totalPrice = 0;
    totalRefundPrice = 0;
    payPlatformMap = PAY_PLATFORM_MAP;
    paySceneMap = ORG_SELECT_PAY_INFO_SCENE_MAP;
    orderStatusMap = ORDER_STATUS_MAP;
    orderStatusFlowMap = ORDER_STATUS_FLOW_MAP;
    shippingStatusMap = SHIPPING_STATUS_MAP;
    refundSourceMap = refund_SOURCE_MAP;
    orderHibianliStatusMap = ORDER_HI_FLOW;
    orderType = "";
    dynamic: boolean;
    isVisible = false;
    synOrder = {
        amount: 0,
        payTime: null,
        orderId: null,
    };
    hours: number;
    minutes: number;
    seconds: number;
    syncOrder: boolean; // 同步服务订单判断条件
    dataLoading = false;

    disabledDate = (current: Date): boolean => {
        return differenceInCalendarDays(current, new Date()) > 0;
    };
    disabledDateTime: DisabledTimeFn = () => ({
        nzDisabledHours: () => this.range(this.hours, 24),
        nzDisabledMinutes: () => this.range(this.minutes, 60),
        nzDisabledSeconds: () => this.range(this.seconds, 60)
    });

    constructor(
        private realTimeOrderService: RealTimeOrderService,
        private message: NzMessageService,
        private modalService: NzModalService,
        private modal: NzModalRef,
        private refundOrderService: RefundOrderService,
        private historyOrderService: HistoryOrderService,
    ) {
    }

    close() {
        this.modal.close({changed: false});
    }

    refund(item) {
        const params: any = {};
        params.orderId = this.orderData.orderId;
        params.sequenceNo = item.sequenceNo;
        this.modalService.confirm({
            nzTitle: `${item.goodsName} - 确认退款？`,
            nzContent: `确认将此商品退款吗？`,
            nzOkDanger: true,
            nzOnOk: () => {
                this.realTimeOrderService.refundItem(params).subscribe(
                    () => {
                        this.modalService.success({
                            nzTitle: "提示",
                            nzContent: `已提交退款申请，请稍后点击“刷新按钮”查看退款结果`,
                            nzOkText: "我知道了",
                        });
                    },
                    () => {
                        this.message.error("退款失败");
                    },
                );
            },
        });
    }

    refresh() {
        if (this.orderType === "history" && !this.dynamic) {
            this.historyOrderService.orderDetail(this.orderData.orderId).subscribe(
                res => {
                    this.orderData = res;
                    this.refreshRefundAccount(this.orderData.goodsList);
                },
                res => {
                });
        } else if (this.orderType === "realTime" && !this.dynamic) {
            this.realTimeOrderService.orderDetail(this.orderData.orderId).subscribe(
                res => {
                    this.orderData = res;
                    this.refreshRefundAccount(this.orderData.goodsList);
                },
                res => {
                });
        } else if (this.orderType === "refund" && !this.dynamic) {
            this.refundOrderService.orderDetail(this.orderData.orderId).subscribe(
                res => {
                    this.orderData = res;
                    this.refreshRefundAccount(this.orderData.goodsList);
                },
                res => {
                });
        } else if (this.orderType === "history" && this.dynamic) {
            this.historyOrderService.dynamicOrderDetail(this.orderData.orderId).subscribe(
                res => {
                    this.orderData = res;
                    this.refreshRefundAccount(this.orderData.goodsList);
                },
                res => {
                });
        } else if (this.orderType === "realTime" && this.dynamic) {
            this.realTimeOrderService.dynamicOrderDetail(this.orderData.orderId).subscribe(
                res => {
                    this.orderData = res;
                    this.refreshRefundAccount(this.orderData.goodsList);
                },
                res => {
                });
        } else if (this.orderType === "refund" && this.dynamic) {
            this.refundOrderService.dynamicOrderDetail(this.orderData.orderId).subscribe(
                res => {
                    this.orderData = res;
                    this.refreshRefundAccount(this.orderData.goodsList);
                },
                res => {
                });
        }
    }

    refreshRefundAccount(goodsList: any) {
        this.totalRefundPrice = 0;
        for (let i = 0; i < goodsList.length; i++) {
            if (this.orderData.goodsList[i].refunded === "1") {
                this.totalRefundPrice += goodsList[i].standardPrice;
            }
        }
    }

    pullBlack() {
        if (this.orderData.customerId) {
            this.modalService.confirm({
                nzTitle: `${this.orderData.customerId} - 确认拉黑？`,
                nzContent: `确认拉黑该用户？`,
                nzOkType: "primary",
                nzOnOk: () => {
                    this.realTimeOrderService.pullBlack({customerId: this.orderData.customerId}).subscribe(
                        (res) => {
                            if (res.result === 1) {
                                this.message.success("拉黑成功！");
                            }
                        },
                        () => {
                        },
                    );
                },
            });
        } else {
            this.message.warning("用户ID为空");
            return;
        }
    }

    synchronizeOrder() {
        if (this.synOrder.payTime == null) {
            this.message.warning("请选择收款时间");
            return;
        }
        this.realTimeOrderService.syncOrder(this.synOrder)
            .subscribe(
                res => {
                    if (res.result === 1) {
                        this.message.success("同步服务订单成功");
                        this.isVisible = false;
                    }
                },
                () => {
                }
            );
    }

    queryAliAuth() {
        this.dataLoading = true;
        this.realTimeOrderService.queryAliAuth({customerId: this.orderData.customerId, orderId: this.orderData.orderId}).subscribe(
            (res) => {
                this.releaseAgreement();
                this.dataLoading = false;
            },
            () => {
                this.dataLoading = false;
            }
        );
    }

    releaseAgreement() {
        this.modalService.confirm({
            nzTitle: "确认解约？",
            nzOkType: "primary",
            nzOnOk: () => {
                this.realTimeOrderService.cancelAliAuth({orderId: this.orderData.orderId, customerId: this.orderData.customerId}).subscribe(
                    (res) => {
                        if (res.result === 1) {
                            this.message.success("解约成功！");
                        }
                    },
                    () => {
                    },
                );
            },
        });
    }

    range(start: number, end: number): number[] {
        const result: number[] = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    timeChange() {
        this.hours = this.synOrder.payTime.getHours() + 1;
        this.minutes = this.synOrder.payTime.getMinutes() + 1;
        this.seconds = this.synOrder.payTime.getSeconds() + 1;
    }

    showModal() {
        this.isVisible = true;
        this.synOrder.payTime = new Date();
    }

    handleCancel() {
        this.isVisible = false;
    }

    ngOnInit(): void {
        for (let i = 0; i < this.orderData.goodsList.length; i++) {
            if (this.orderData.goodsList[i].refunded === "1") {
                this.totalRefundPrice = this.totalRefundPrice + this.orderData.goodsList[i].standardPrice;
            }
            this.totalPrice = this.totalPrice + this.orderData.goodsList[i].standardPrice;
        }
        this.synOrder.orderId = this.orderData.orderId;
        if (this.dynamic && this.orderData.orderStatus === "1") {
            if (
                this.orderData.payScene === "wechat_pay_score_mini_qr" ||
                this.orderData.payScene === "ali_auth_pay_mini_qr"
            ) {
                this.syncOrder = true;
            }
        }
    }

}
