import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "encryptionText",
})
export class EncryptionTextPipe implements PipeTransform {

    transform(input: string): string {
        if (typeof input === "string") {
            if (input.length > 3) {
                return input.substring(0, 3) + "******" + input.substring(input.length - 3, input.length);
            }
        }
        return input;
    }
}
