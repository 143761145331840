import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SERVER_API_PREFIX} from "../../app.constants";

@Injectable({
    providedIn: "root",
})
export class DownLoadListService {
    constructor(private http: HttpClient) {
    }

    query(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/export/excel`, {
            params,
            observe: "response",
        });
    }

    deleteExport(id: BigInteger): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/export/delete?id=${id}`);
    }

    reTryExport(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/historyOrder/export`, {params});
    }

    downloadById(id: String): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/export/download/${id}`);
    }

}
