import {Route} from "@angular/router";

export const pointManageRoute: Route = {
    path: "point-manage",
    loadChildren: () => import("./point-manage.module").then(m => m.PointManageModule),
    data: {
        menuParent: "organization",
        menuLabel: "点位管理",
    }
};
