import {Route} from "@angular/router";

export const extendCommunicateLogRoute: Route = {
    path: "extend-communicate-log",
    loadChildren: () => import("./extend-communicate-log.module")
        .then(m => m.ExtendCommunicateLogModule),
    data: {
        menuParent: "logs",
        menuLabel: "视觉柜通讯日志",
    },
};
