import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: "formatCodes",
})
export class FormatCodesPipe implements PipeTransform {

    transform(value: string, indent?: number): string {
        if (!value || typeof value !== "string") {
            return value;
        }
        value = value.trim();
        if (value[0] === "{" || value[0] === "[") {
            try {
                return JSON.stringify(JSON.parse(value), null, indent);
            } catch (e) {
            }
        }
        if (value[0] === "<") {
            return this.formatXml(value, Array.from({length: indent}).reduce(c => c + " ", ""));
        }

        return value;
    }

    formatXml(xml, indentStr) {
        let formatted = "";
        const reg = /(>)(<)(\/*)/g;
        xml = xml.replace(reg, "$1\r\n$2$3");
        let pad = 0;
        for (const node of xml.split("\r\n")) {
            let indent = 0;
            if (node.match(/.+<\/\w[^>]*>$/)) {
                indent = 0;
            } else if (node.match(/^<\/\w/)) {
                if (pad !== 0) {
                    pad -= 1;
                }
            } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
                indent = 1;
            } else {
                indent = 0;
            }

            let padding = "";
            for (let i = 0; i < pad; i++) {
                padding += indentStr;
            }

            formatted += padding + node + "\r\n";
            pad += indent;
        }

        return formatted;
    }
}
