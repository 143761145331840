import {Route} from "@angular/router";

export const organizationManageRoute: Route = {
    path: "organization-manage",
    loadChildren: () => import("./organization-manage.module").then(m => m.OrganizationManageModule),
    data: {
        menuParent: "organization",
        menuLabel: "组织管理",
    },
};
