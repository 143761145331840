import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {Store} from "@ngxs/store";
import {GlobalSelectedOrganization} from "@/global/store/global-selected-organization";
import {environment} from "../../../environments/environment";

@Injectable()
export class OrganizationHeaderInterceptor implements HttpInterceptor {
    constructor(private store: Store) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const selectOrganization = this.store.selectSnapshot(GlobalSelectedOrganization.currentSelected);
        let request: HttpRequest<any> = req;
        if (selectOrganization?.organizationLevel && selectOrganization?.organizationId) {
            request = req.clone({
                headers: req.headers
                    .set("X-current-organization-level", selectOrganization.organizationLevel)
                    .set("X-current-organization-id", selectOrganization.organizationId),
            });
        } else if (!environment.production) {
            // console.error("当前已选组织有值为空");
        }
        return next.handle(request);
    }
}
