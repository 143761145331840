import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SERVER_API_PREFIX} from "@/app.constants";

@Injectable({
    providedIn: "root",
})
export class RealTimeOrderService {
    constructor(private http: HttpClient) {
    }

    query(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/list`, {
            params,
        });
    }

    orderDetail(orderId: String): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/detail/${orderId}`);
    }

    refundItem(req: any): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/realTimeOrder/refund`, req);
    }

    export(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/export`, {params});
    }

    exportOrderGoods(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/export/orderGoods`, {params});
    }

    dynamicQuery(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/hibianli/list`, {
            params,
        });
    }

    dynamicOrderDetail(orderId: String): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/hibianli/detail/${orderId}`);
    }

    syncOrder(req: any): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/realTimeOrder/hibianli/order/sync`, req);
    }

    pullBlack(req: any): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/realTimeOrder/hibianli/kickout`, req);
    }

    selectOrderComplaint(orderId: String): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/complaint/${orderId}`);
    }

    queryOrderComplaint(req: any): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/realTimeOrder/complaint/submit`, req);
    }

    queryVideoDetail(merchantId: string, orderId: string): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/hibianli/video/${merchantId}/${orderId}`);
    }

    cancelAliAuth(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/cancel/aliAuth`, {params});
    }

    queryAliAuth(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/realTimeOrder/query/aliAuth`, {params});
    }


}
