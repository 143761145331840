import {Action, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {SelectedOrganization} from "./selected-organization";

export class Update {
    static readonly type = "Update";
    updateData: SelectedOrganization[];

    constructor(updateChooseInfo: SelectedOrganization[]) {
        this.updateData = updateChooseInfo;
    }
}

@State<SelectedOrganization[]>({
    name: "globalSelectedOrganization",
    defaults: [],
})
@Injectable()
export class GlobalSelectedOrganization {
    @Selector()
    static currentSelected(state: SelectedOrganization[]): SelectedOrganization {
        return state[state.length - 1];
    }

    @Action(Update)
    update(context: StateContext<SelectedOrganization[]>, {updateData}: Update) {
        context.setState(updateData);
    }
}
