import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TestPayInfoComponent} from "./test-pay-info.component";
import {QRCodeModule} from "angularx-qrcode";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzStepsModule} from "ng-zorro-antd/steps";
import {NzMessageModule} from "ng-zorro-antd/message";

@NgModule({
    imports: [
        CommonModule,
        QRCodeModule,
        NzAlertModule,
        NzButtonModule,
        NzStepsModule,
        NzMessageModule,
    ],
    exports: [
        TestPayInfoComponent,
    ],
    declarations: [
        TestPayInfoComponent,
    ],
})
export class TestPayInfoModule {
}
