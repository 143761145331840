import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "svsp-horizontal-proportion-bar",
    templateUrl: "./horizontal-proportion-bar.component.html",
    styleUrls: ["./horizontal-proportion-bar.component.less"],
})
export class HorizontalProportionBarComponent implements OnInit {
    private _data: ProportionData[] = [];
    @Input()
    set data(data: ProportionData[]) {
        for (const item of data) {
            if (!item.value.toString() || item.value < 0) {
                this._data = [];
                throw new Error(`非法的值`);
            }
        }
        this._data = data;
    }

    @Input()
    totalValue?: number;

    @Input()
    height = 20;

    get computedTotalValue(): number {
        const computed = this._data.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0);
        return this.totalValue > computed ? this.totalValue : computed;
    }

    get values(): ComputedValue[] {
        let currentVal = 0;
        const total = this.computedTotalValue;
        if (total <= 0) {
            return [];
        }
        return this._data.map(item => ({percent: (currentVal += item.value) / total * 100, color: item.color})).reverse();
    }

    constructor() {
    }

    ngOnInit(): void {
    }

}

export interface ProportionData {
    value: number;
    color: string;
}

interface ComputedValue {
    percent: number;
    color: string;
}
