import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: "svsp-standard-title-bar",
    templateUrl: "./standard-title-bar.component.html",
    styleUrls: ["./standard-title-bar.component.less"]
})
export class StandardTitleBarComponent implements OnInit {
    @Input()
    title: string;
    @Input()
    url: string;

    constructor() {
    }

    ngOnInit() {
    }

}
