import {Route} from "@angular/router";

export const firmwareUpgradeRoute: Route = {
    path: "firmware-upgrade",
    loadChildren: () => import("./firmware-upgrade.module")
        .then(m => m.FirmwareUpgradeModule),
    data: {
        menuParent: "device",
        menuLabel: "固件升级",
        requiredPrivilege: "firmwareUpgrade:view"
    },
};
