import {Route} from "@angular/router";

export const payInfoRoute: Route = {
    path: "pay-info",
    loadChildren: () => import("./pay-info.module").then(m => m.PayInfoModule),
    data: {
        menuParent: "payManage",
        menuLabel: "支付信息管理",
        requiredPrivilege: "payinfo:view"
    }
};
