import {Route} from "@angular/router";

export const industrialControlComputerListRoute: Route = {
    path: "industrial-control-computer-list",
    loadChildren: () => import("./industrial-control-computer-list.module").then(m => m.IndustrialControlComputerListModule),
    data: {
        menuParent: "device",
        menuLabel: "工控列表",
    }
};
