import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {UserInfoService} from "@/global/user-info.service";

/**
 * 快速地检查当前用户是否有该权限Code，并决定元素是否渲染
 * 与ngIf逻辑类似，权限Code检查通过时才将元素渲染到相应位置，权限Code不存在时将直接移除DOM元素
 */
@Directive({
    selector: "[svspRequiresPermissions]"
})
export class RequiresPermissionsDirective {
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef
    ) {
    }

    @Input() set svspRequiresPermissions(privilegeCode: string) {
        if (typeof privilegeCode === "string") {
            if (UserInfoService.hasPrivilege(privilegeCode) && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!UserInfoService.hasPrivilege(privilegeCode) && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        } else {
            throw new Error(`传入值必需是一个权限Code字符串`);
        }
    }
}
