import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {WechatPayInfoComponent} from "./wechat-pay-info.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SubMchComponent} from "./sub-mch/sub-mch.component";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzUploadModule} from "ng-zorro-antd/upload";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzDatePickerModule,
        NzFormModule,
        NzInputModule,
        NzSelectModule,
        NzSpinModule,
        NzUploadModule,
    ],
    exports: [
        WechatPayInfoComponent,
        SubMchComponent,
    ],
    declarations: [
        WechatPayInfoComponent,
        SubMchComponent,
    ],
})
export class WechatPayInfoModule {
}
