import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StandardTitleBarComponent} from "./standard-title-bar.component";
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";

@NgModule({
    imports: [
        CommonModule,
        NzBreadCrumbModule
    ],
    exports: [
        StandardTitleBarComponent
    ],
    declarations: [
        StandardTitleBarComponent
    ]
})
export class StandardTitleBarModule {
}
