import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {Store} from "@ngxs/store";
import {GlobalSelectedOrganization} from "@/global/store/global-selected-organization";
import {UserInfoService} from "@/global/user-info.service";

type OrgType = "ADMIN" | "CORPORATION" | "BRANCH_REGION" | "REGION" | "MERCHANT" | "MERCHANT_LINE" | "MERCHANT_POINT";

/**
 * 快速地检查当前选择层级是否有该权限Code，并决定元素是否渲染
 * 与ngIf逻辑类似，权限Code检查通过时才将元素渲染到相应位置，权限Code不存在时将直接移除DOM元素
 */
@Directive({
    selector: "[svspRequiresSelectOrgLevel]"
})
export class RequiresSelectOrgLevelDirective {
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private store: Store
    ) {
    }

    @Input() set svspRequiresSelectOrgLevel(levelCode: OrgType[]) {
        if (!Array.isArray(levelCode)) {
            throw new Error(`传入值必需是一个权限Code字符串数组`);
        } else {
            const hasLevel = levelCode.some(code =>
                code === this.store.selectSnapshot(GlobalSelectedOrganization.currentSelected).organizationLevel);
            if (hasLevel && !this.hasView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!hasLevel && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        }
    }
}
