import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NzModalService} from "ng-zorro-antd/modal";
import {PayInfo} from "../pay-info";

@Component({
    selector: "svsp-jd-pay-info",
    templateUrl: "./jd-pay-info.component.html",
    styleUrls: ["./jd-pay-info.component.less"]
})
export class JdPayInfoComponent implements OnInit {
    private _payInfo: PayInfo;
    validateForm: FormGroup;

    private validateTimer;
    private skipValidate = true;
    private _disabled = false;

    @Output()
    payInfoChange = new EventEmitter();

    @Output()
    payInfoValidChange = new EventEmitter();

    @Input()
    get payInfo(): PayInfo {
        return this._payInfo;
    }

    @Input()
    set disabled(value) {
        this._disabled = value;
        this.setFormDisableStatus();
    }

    private setFormDisableStatus() {
        if (this.validateForm) {
            for (const control of Object.values(this.validateForm.controls)) {
                if (this._disabled) {
                    control.disable();
                } else {
                    control.enable();
                }
            }
        }
    }

    set payInfo(payInfo) {
        if (payInfo) {
            this._payInfo = payInfo;
            if (this.validateForm) {
                this.validateForm.setValue({
                    payInfoId: this._payInfo.payInfoId || "",
                    createUserId: this._payInfo.createUserId || "",
                    jdMerchantNo: this._payInfo.jdMerchantNo || "",
                    jdDesKey: this._payInfo.jdDesKey || "",
                    jdSaltMd5Key: this._payInfo.jdSaltMd5Key || "",
                    jdSystemId: this._payInfo.jdSystemId || ""
                });
            }
            if (!this.skipValidate) {
                this.payInfoChange.emit(this._payInfo);
            }
            this.skipValidate = false;
        }
    }

    formChanged() {
        this.payInfo = {...this.payInfo, ...this.validateForm.getRawValue()};
    }

    inputInvalid(formControlName: string) {
        clearTimeout(this.validateTimer);
        if (!this.skipValidate) {
            this.validateTimer = setTimeout(() => {
                this.payInfoValidChange.emit(this.validateForm.valid);
            }, 50);
        }
        return (this.validateForm.get(formControlName).dirty && this.validateForm.get(formControlName).errors);
    }

    constructor(private fb: FormBuilder,
                private modalService: NzModalService) {
    }

    ngOnInit() {
        this.validateForm = this.fb.group({
            payInfoId: [""],
            createUserId: [""],
            jdMerchantNo: ["", [Validators.required]],
            jdDesKey: ["", [Validators.required]],
            jdSaltMd5Key: ["", [Validators.required]],
            jdSystemId: ["", [Validators.required]]
        });
        this.setFormDisableStatus();
        this.payInfo = this._payInfo;
    }
}
