import {Route} from "@angular/router";

export const downLoadListRoute: Route = {
    path: "down-load-list",
    loadChildren: () => import("./down-load-list.module").then(m => m.DownLoadListModule),
    data: {
        menuParent: "other",
        menuLabel: "下载中心",
    }
};
