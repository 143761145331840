import {Route} from "@angular/router";

export const systemDictionaryRoute: Route = {
    path: "system-dictionary",
    loadChildren: () => import("./system-dictionary.module").then(m => m.SystemDictionaryModule),
    data: {
        menuParent: "systemLibrary",
        menuLabel: "数据字典",
        requiredPrivilege: "sysDic:view"
    }
};
