import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {JdPayInfoComponent} from "./jd-pay-info.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzModalModule} from "ng-zorro-antd/modal";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzFormModule,
        NzInputModule,
        NzModalModule,
    ],
    exports: [
        JdPayInfoComponent,
    ],
    declarations: [
        JdPayInfoComponent,
    ],
})
export class JdPayInfoModule {
}
