import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalService} from "ng-zorro-antd/modal";
import {Router} from "@angular/router";
import {UserInfoService} from "../user-info.service";

@Injectable()
export class HandleErrorInterceptor implements HttpInterceptor {
    constructor(private message: NzMessageService,
                private modalService: NzModalService,
                private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(tap(
                event => {
                    if (!environment.production) {
                        console.log(event);
                    }
                    if (event instanceof HttpResponse && event.body.result === 0) {
                        this.message.warning(event.body.fault.message);
                    }
                },
                (err: any) => {
                    if (!environment.production) {
                        console.log(err);
                    }
                    if (err instanceof HttpErrorResponse) {
                        switch (err.status) {
                            case 401:
                                UserInfoService.saveLastUrl(this.router.url);
                                UserInfoService.clearUserBaseInfo();
                                this.router.navigate(["/login"])
                                    .then(() => {
                                        setTimeout(() => this.modalService.closeAll(), 200);
                                    });
                                break;
                            case 403:
                            // todo: 重定向到403
                            default:
                                if (err.error && err.error.fault) {
                                    this.message.error(err.error.fault.message);
                                } else {
                                    this.message.error(`${err.status} - ${environment.production ? err.statusText : err.message}`);
                                }
                        }
                    } else if (err.body && err.body.fault) {
                        this.message.warning(err.body.fault.message);
                    }
                }));
    }
}
