import {Route} from "@angular/router";

export const terminalCommunicateLogRoute: Route = {
    path: "terminal-communicate-log",
    loadChildren: () => import("./terminal-communicate-log.module")
        .then(m => m.TerminalCommunicateLogModule),
    data: {
        menuParent: "logs",
        menuLabel: "终端通信日志",
    },
};
