import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LoginComponent} from "./login.component";
import {ReactiveFormsModule} from "@angular/forms";
import {SvspCollapseModule} from "@/components/svsp-collapse/svsp-collapse.module";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzIconModule} from "ng-zorro-antd/icon";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SvspCollapseModule,
        NzButtonModule,
        NzFormModule,
        NzInputModule,
        NzCheckboxModule,
        NzIconModule,
    ],
    declarations: [LoginComponent],
})
export class LoginModule {
}
