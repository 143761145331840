import {Route} from "@angular/router";

export const roleRoute: Route = {
    path: "role",
    loadChildren: () => import("./role.module").then(m => m.RoleModule),
    data: {
        menuParent: "organization",
        menuLabel: "角色管理",
    }
};
