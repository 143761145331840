import {Route} from "@angular/router";

export const userOperationLogRoute: Route = {
    path: "user-operation-log",
    loadChildren: () => import("./user-operation-log.module")
        .then(m => m.UserOperationLogModule),
    data: {
        menuParent: "logs",
        menuLabel: "用户操作日志",
    },
};
