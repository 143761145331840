import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HorizontalProportionBarComponent} from "./horizontal-proportion-bar.component";


@NgModule({
    declarations: [
        HorizontalProportionBarComponent,
    ],
    exports: [
        HorizontalProportionBarComponent,
    ],
    imports: [
        CommonModule,
    ],
})
export class HorizontalProportionBarModule {
}
