export class LoadingMask {
    static remove() {
        const loadingMask = document.querySelector("#splash-screen");
        if (loadingMask) {
            if (loadingMask.remove) {
                loadingMask.remove();
            } else {
                loadingMask.parentElement.removeChild(loadingMask);
            }
        }
    }
}
