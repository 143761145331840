import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TINY_ATTACHMENT_UPLOAD_ADDRESS} from "@/app.constants";
import {PayInfo} from "../pay-info";

@Component({
    selector: "svsp-union-pay-info",
    templateUrl: "./union-pay-info.component.html",
    styleUrls: ["./union-pay-info.component.less"]
})
export class UnionPayInfoComponent implements OnInit {
    private _payInfo: PayInfo;
    validateForm: FormGroup;

    private validateTimer;
    private skipValidate = true;

    private _disabled = false;
    uploadAddress = TINY_ATTACHMENT_UPLOAD_ADDRESS;

    @Output()
    payInfoChange = new EventEmitter();

    @Input()
    get payInfo(): PayInfo {
        return this._payInfo;
    }

    @Output()
    payInfoValidChange = new EventEmitter();

    @Input()
    set disabled(value) {
        this._disabled = value;
        this.setFormDisableStatus();
    }

    private setFormDisableStatus() {
        if (this.validateForm) {
            for (const control of Object.values(this.validateForm.controls)) {
                if (this._disabled) {
                    control.disable();
                } else {
                    control.enable();
                }
            }
        }
    }

    set payInfo(payInfo) {
        if (payInfo) {
            this._payInfo = payInfo;
            if (this.validateForm) {
                this.validateForm.setValue({
                    payInfoId: this._payInfo.payInfoId || "",
                    createUserId: this._payInfo.createUserId || "",
                    unionMerId: this._payInfo.unionMerId || "",
                    unionCertPassword: this._payInfo.unionCertPassword || "",
                    unionCert: this._payInfo.unionCert || null,
                    unionMiddleCert: this._payInfo.unionMiddleCert || null,
                    unionRootCert: this._payInfo.unionRootCert || null,
                    unionVerifySignCert: this._payInfo.unionVerifySignCert || null,
                });
            }
            if (!this.skipValidate) {
                this.payInfoChange.emit(this._payInfo);
            }
            this.skipValidate = false;
        }
    }

    formChanged() {
        this.payInfo = {...this.payInfo, ...this.validateForm.getRawValue()};
    }

    certUploadStatusChanged(event) {
        if (event.type === "success") {
            this.validateForm.get("unionCert").setValue(event.file.response.tinyAttachmentId);
            this.validateForm.get("unionCert").markAsDirty();
            this.validateForm.get("unionCert").markAsTouched();
            this.formChanged();
        }
    }

    middleCertUploadStatusChanged(event) {
        if (event.type === "success") {
            this.validateForm.get("unionMiddleCert").setValue(event.file.response.tinyAttachmentId);
            this.validateForm.get("unionMiddleCert").markAsDirty();
            this.validateForm.get("unionMiddleCert").markAsTouched();
            this.formChanged();
        }
    }

    rootCertUploadStatusChanged(event) {
        if (event.type === "success") {
            this.validateForm.get("unionRootCert").setValue(event.file.response.tinyAttachmentId);
            this.validateForm.get("unionRootCert").markAsDirty();
            this.validateForm.get("unionRootCert").markAsTouched();
            this.formChanged();
        }
    }

    verifySignCertUploadStatusChanged(event) {
        if (event.type === "success") {
            this.validateForm.get("unionVerifySignCert").setValue(event.file.response.tinyAttachmentId);
            this.validateForm.get("unionVerifySignCert").markAsDirty();
            this.validateForm.get("unionVerifySignCert").markAsTouched();
            this.formChanged();
        }
    }

    inputInvalid(formControlName: string) {
        clearTimeout(this.validateTimer);
        if (!this.skipValidate) {
            this.validateTimer = setTimeout(() => {
                this.payInfoValidChange.emit(this.validateForm.valid);
            }, 50);
        }
        return (this.validateForm.get(formControlName).dirty && this.validateForm.get(formControlName).errors);
    }

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        this.validateForm = this.fb.group({
            payInfoId: [""],
            createUserId: [""],
            unionMerId: [null, [Validators.required]],
            unionCertPassword: [null, [Validators.required]],
            unionCert: [null, [Validators.required]],
            unionMiddleCert: [null, [Validators.required]],
            unionRootCert: [null, [Validators.required]],
            unionVerifySignCert: [null, [Validators.required]]
        });
        this.payInfo = this._payInfo;
        this.setFormDisableStatus();
    }
}
