import {Route} from "@angular/router";

export const machineListRoute: Route = {
    path: "machine-list",
    loadChildren: () => import(/* webpackChunkName:"device-goods-module" */"./machine-list.module").then(m => m.MachineListModule),
    data: {
        menuParent: "device",
        menuLabel: "机器列表",
    }
};
