import {Routes} from "@angular/router";
import {DashboardComponent} from "@/pages/public/dashboard/dashboard.component";
import {channelTemplateRoute} from "@/pages/device/channel-tempalte/channel-template.route";
import {consoleRoute} from "@/pages/dashboard/console/console.route";
import {deviceListRoute} from "@/pages/device/device-list/device-list.route";
import {deviceStatusRoute} from "@/pages/device/device-status/device-status.route";
import {downLoadListRoute} from "@/pages/down-load-list/down-load-list.route";
import {extendCommunicateLogRoute} from "@/pages/logs/extend-communicate-log/extend-communicate-log.route";
import {firmwareUpgradeRoute} from "@/pages/firmware-upgrade/firmware-upgrade.route";
import {historyOrderRoute} from "@/pages/order/history-order/history-order.route";
import {industrialControlComputerListRoute} from "@/pages/device/industrial-control-computer-list/industrial-control-computer-list.route";
import {machineLackOfGoodsStatusRoute} from "@/pages/device/machine-lack-of-goods-status/machine-lack-of-goods-status.route";
import {machineListRoute} from "@/pages/device/machine-list/machine-list.route";
import {machineModelRoute} from "@/pages/device/machine-model/machine-model.route";
import {merchantGoodsRoute} from "@/pages/goods-manage/merchant-goods/merchant-goods.route";
import {orderHandleLogLogRoute} from "@/pages/logs/order-handle-log/order-handle-log.route";
import {orderPaymentLogLogRoute} from "@/pages/logs/order-payment-log/order-payment-log.route";
import {organizationManageRoute} from "@/pages/organization-manage/organization-manage.route";
import {payInfoRoute} from "@/pages/pay-info-manage/pay-info/pay-info.route";
import {pointManageRoute} from "@/pages/line-or-point/point-manage/point-manage.route";
import {publicGoodsRoute} from "@/pages/goods-manage/public-goods/public-goods.route";
import {realTimeOrderRoute} from "@/pages/order/real-time-order/real-time-order.route";
import {refundOrderRoute} from "@/pages/order/refund-order/refund-order.route";
import {roleRoute} from "@/pages/role/role.route";
import {serviceProviderRoute} from "@/pages/pay-info-manage/service-provider/service-provider.route";
import {systemDictionaryRoute} from "@/pages/logs/system-dictionary/system-dictionary.route";
import {terminalCommunicateLogRoute} from "@/pages/logs/terminal-communicate/terminal-communicate-log.route";
import {userOperationLogRoute} from "@/pages/logs/user-operation/user-operation-log.route";
import {userRoute} from "@/pages/user/user.route";

export const pagesRoute: Routes = [
    {path: "", component: DashboardComponent},
    channelTemplateRoute,
    consoleRoute,
    deviceListRoute,
    deviceStatusRoute,
    downLoadListRoute,
    realTimeOrderRoute,
    historyOrderRoute,
    industrialControlComputerListRoute,
    machineLackOfGoodsStatusRoute,
    machineListRoute,
    firmwareUpgradeRoute,
    publicGoodsRoute,
    machineModelRoute,
    merchantGoodsRoute,
    terminalCommunicateLogRoute,
    orderHandleLogLogRoute,
    orderPaymentLogLogRoute,
    userOperationLogRoute,
    extendCommunicateLogRoute,
    organizationManageRoute,
    payInfoRoute,
    pointManageRoute,
    refundOrderRoute,
    roleRoute,
    serviceProviderRoute,
    systemDictionaryRoute,
    userRoute,
];
