import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";

import {AppComponent} from "./app.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule} from "@angular/forms";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {NZ_CONFIG, NzConfig} from "ng-zorro-antd/core/config";
import {NZ_I18N, zh_CN} from "ng-zorro-antd/i18n";
import {registerLocaleData} from "@angular/common";
import zh from "@angular/common/locales/zh";
import {AppRoutingModule} from "./app-routing.module";
import {LoginModule} from "./global/login/login.module";
import {PagesModule} from "./pages/pages.module";
import {HttpService} from "./global/request-utils/http.service";
import {HandleErrorInterceptor} from "./global/request-utils/handle-error-interceptor";
import {ResponseSimplifyInterceptor} from "./global/request-utils/response-simplify-interceptor";
import {DateSerializeInterceptor} from "./global/request-utils/date-serialize-interceptor";
import {StandardModalService} from "./global/standard-modal.service";
import {NgxsModule} from "@ngxs/store";
import {GlobalSelectedOrganization} from "./global/store/global-selected-organization";
import {HIGHLIGHT_OPTIONS} from "ngx-highlightjs";
import {OrganizationHeaderInterceptor} from "@/global/request-utils/organization-header-interceptor.service";
import {NgxsStoragePluginModule, StorageOption} from "@ngxs/storage-plugin";

registerLocaleData(zh);

const ngZorroConfig: NzConfig = {
    message: {
        nzDuration: 5000,
    },
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        LoginModule,
        PagesModule,
        NgxsModule.forRoot([GlobalSelectedOrganization]),
        NgxsStoragePluginModule.forRoot({
            key: [GlobalSelectedOrganization],
            storage: StorageOption.SessionStorage,
        }),
    ],
    providers: [
        HandleErrorInterceptor,
        ResponseSimplifyInterceptor,
        DateSerializeInterceptor,
        OrganizationHeaderInterceptor,
        {provide: NZ_I18N, useValue: zh_CN},
        {provide: NZ_CONFIG, useValue: ngZorroConfig},
        {provide: NzModalService, useClass: StandardModalService},
        {provide: HttpClient, useClass: HttpService},
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                coreLibraryLoader: () => import("highlight.js/lib/core"),
                languages: {
                    json: () => import("highlight.js/lib/languages/json"),
                    xml: () => import("highlight.js/lib/languages/xml"),
                },
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
