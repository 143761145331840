import {Route} from "@angular/router";

export const refundOrderRoute: Route = {
    path: "refund-order",
    loadChildren: () => import(/* webpackChunkName:"order-module" */"./refund-order.module").then(m => m.RefundOrderModule),
    data: {
        menuParent: "order",
        menuLabel: "退款订单",
    }
};
