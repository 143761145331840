import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AlipayPayInfoComponent} from "./alipay-pay-info.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {SubAlipayComponent} from "@/components/alipay-pay-info/sub-alipay/sub-alipay.component";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzSelectModule} from "ng-zorro-antd/select";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzFormModule,
        NzInputModule,
        NzSpinModule,
        NzSelectModule,
    ],
    exports: [
        AlipayPayInfoComponent,
        SubAlipayComponent,
    ],
    declarations: [
        AlipayPayInfoComponent,
        SubAlipayComponent,
    ]
})
export class AlipayPayInfoModule {
}
