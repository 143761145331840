import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PayInfo} from "../pay-info";
import {isDate, startOfDay} from "date-fns";
import {TINY_ATTACHMENT_UPLOAD_ADDRESS, WECHAT_DOMAIN_VERIFY_FILE_UPLOAD_ADDRESS} from "@/app.constants";

const today = startOfDay(new Date());

@Component({
    selector: "svsp-wechat-pay-info",
    templateUrl: "./wechat-pay-info.component.html",
    styleUrls: ["./wechat-pay-info.component.less"]
})
export class WechatPayInfoComponent implements OnInit {
    private _payInfo: PayInfo;
    validateForm: FormGroup;

    private validateTimer;
    private skipValidate = true;

    uploadAddress = TINY_ATTACHMENT_UPLOAD_ADDRESS;
    domainVerifyFileUploadAddress = WECHAT_DOMAIN_VERIFY_FILE_UPLOAD_ADDRESS;

    domainVerifyFileUploaded = false;
    domainVerifyFileName: string;
    domainVerifyFileExpire: number;
    private _disabled = false;

    @Input()
    providerPayInfo: boolean;

    @Output()
    payInfoChange = new EventEmitter();

    @Input()
    get payInfo(): PayInfo {
        return this._payInfo;
    }

    @Output()
    payInfoValidChange = new EventEmitter();

    @Input()
    set disabled(value) {
        this._disabled = value;
        this.setFormDisableStatus();
    }

    private setFormDisableStatus() {
        if (this.validateForm) {
            for (const control of Object.values(this.validateForm.controls)) {
                if (this._disabled) {
                    control.disable();
                } else {
                    control.enable();
                }
            }
        }
    }

    disabledDate(current: Date): boolean {
        return current <= today;
    }

    set payInfo(payInfo) {
        if (payInfo) {
            this._payInfo = payInfo;
            if (this.validateForm) {
                this.validateForm.setValue({
                    payInfoId: this._payInfo.payInfoId || "",
                    createUserId: this._payInfo.createUserId || "",
                    wechatAppId: this._payInfo.wechatAppId || "",
                    wechatAppSecret: this._payInfo.wechatAppSecret || "",
                    wechatMchId: this._payInfo.wechatMchId || "",
                    wechatApiKey: this._payInfo.wechatApiKey || "",
                    wechatApiCertExpireTimeStamp: this._payInfo.wechatApiCertExpireTimeStamp,
                    wechatApiCertId: this._payInfo.wechatApiCertId || "",
                    wechatApiPrivateCertId: this._payInfo.wechatApiPrivateCertId || "",
                    wechatApiCertSerialNumber: this._payInfo.wechatApiCertSerialNumber || "",
                    wechatV3Key: this._payInfo.wechatV3Key || "",
                });
            }
            if (!this.skipValidate) {
                this.payInfoChange.emit(this._payInfo);
            }
            this.skipValidate = false;
        }
    }

    formChanged() {
        this.payInfo = {...this.payInfo, ...this.validateForm.getRawValue()};
    }

    expireTimeChanged(event) {
        if (this._payInfo.wechatApiCertExpireTimeStamp !== event) {
            this.formChanged();
        }
    }

    uploadStatusChanged(event) {
        if (event.type === "success") {
            this.validateForm.get("wechatApiCertId").setValue(event.file.response.tinyAttachmentId);
            this.validateForm.get("wechatApiCertId").markAsDirty();
            this.validateForm.get("wechatApiCertId").markAsTouched();
            this.formChanged();
        }
    }

    uploadPrivateCertChanged(event) {
        if (event.type === "success") {
            this.validateForm.get("wechatApiPrivateCertId").setValue(event.file.response.tinyAttachmentId);
            this.validateForm.get("wechatApiPrivateCertId").markAsDirty();
            this.validateForm.get("wechatApiPrivateCertId").markAsTouched();
            this.formChanged();
        }
    }

    domainVerifyFileUploadStatusChanged(event) {
        if (event.type === "success") {
            this.domainVerifyFileUploaded = true;
            this.domainVerifyFileName = event.file.response.fileName;
            this.domainVerifyFileExpire = event.file.response.expireMinutes;
        }
    }

    inputInvalid(formControlName: string) {
        clearTimeout(this.validateTimer);
        if (!this.skipValidate) {
            this.validateTimer = setTimeout(() => {
                this.payInfoValidChange.emit(this.validateForm.valid);
            }, 50);
        }
        return (this.validateForm.get(formControlName).dirty && this.validateForm.get(formControlName).errors);
    }

    constructor(private fb: FormBuilder) {
    }

    ngOnInit() {
        this.validateForm = this.fb.group({
            payInfoId: [""],
            createUserId: [""],
            wechatAppId: [null, [Validators.required]],
            wechatAppSecret: [null, [Validators.required]],
            wechatMchId: [null, [Validators.required]],
            wechatApiKey: [null, [Validators.required]],
            wechatApiCertExpireTimeStamp: [null],
            wechatApiCertId: [null, [Validators.required]],
            wechatApiPrivateCertId: [null, [Validators.required]],
            wechatApiCertSerialNumber: [null, [Validators.required]],
            wechatV3Key: [null, [Validators.required]],
        });
        this.providerPayInfo = this.providerPayInfo === true;
        this.setFormDisableStatus();
        this.payInfo = this._payInfo;
    }
}
