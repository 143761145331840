import {Route} from "@angular/router";

export const deviceListRoute: Route = {
    path: "device-list",
    loadChildren: () => import(/* webpackChunkName:"device-goods-module" */"./device-list.module").then(m => m.DeviceListModule),
    data: {
        menuParent: "device",
        menuLabel: "设备列表",
    }
};
