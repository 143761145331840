import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {isDate} from "date-fns";
import {Injectable} from "@angular/core";

@Injectable()
export class DateSerializeInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let params = req.params;
        for (const key of params?.keys()) {
            // @ts-ignore
            params = params.set(key, params.getAll(key).map(item => {
                // @ts-ignore
                if (item instanceof Date) {
                    return item.valueOf();
                }
                return item;
            }));
        }
        if (req.body) {
            this.convertDate(req.body);
        }
        return next.handle(req.clone({params}));
    }

    private convertDate(obj: Object) {
        for (const key of Object.keys(obj)) {
            if (obj[key] instanceof Date || isDate(obj[key])) {
                obj[key] = obj[key].valueOf();
            } else if (obj[key] instanceof Object) {
                this.convertDate(obj[key]);
            }
        }
    }
}
