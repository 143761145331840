import {Route} from "@angular/router";

export const merchantGoodsRoute: Route = {
    path: "merchant-goods",
    loadChildren: () => import(/* webpackChunkName:"device-goods-module" */"./merchant-goods.module").then(m => m.MerchantGoodsModule),
    data: {
        menuParent: "goods",
        menuLabel: "分公司商品",
    }
};
