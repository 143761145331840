import {Route} from "@angular/router";

export const realTimeOrderRoute: Route = {
    path: "real-time-order",
    loadChildren: () => import("./real-time-order.module").then(m => m.RealTimeOrderModule),
    data: {
        menuParent: "order",
        menuLabel: "实时订单",
    }
};
