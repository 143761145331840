import {Route} from "@angular/router";

export const consoleRoute: Route = {
    path: "console",
    loadChildren: () => import("./console.module").then(m => m.ConsoleModule),
    data: {
        menuParent: "home",
        menuLabel: "主控台",
    }
};
