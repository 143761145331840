import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {MapOptions} from "angular2-baidu-map";
import {NzModalRef} from "ng-zorro-antd/modal";
import {PointManageService} from "@/pages/line-or-point/point-manage/point-manage.service";


@Component({
    selector: "svsp-choose-position",
    templateUrl: "./choose-position.component.html",
    styleUrls: ["./choose-position.component.less"],
})
export class ChoosePositionComponent implements OnInit {
    @ViewChild("inputElement")
    inputElement: ElementRef;
    mapOptions: MapOptions = {
        centerAndZoom: {
            lng: 0,
            lat: 0,
            zoom: 15,
        },
    };
    marker = {
        point: {
            lat: 0,
            lng: 0,
        },
    };
    addComp = {
        cityCode: "",
        cityName: "",
        country: "",
        districtCode: "",
        districtName: "",
        provinceCode: "",
        provinceName: "",
        street: "",
        streetNumber: "",
        town: "",
        address: "",
        mapPointName: ""
    };
    isCreate = false;
    data: any = {};

    constructor(
        private modal: NzModalRef,
        private pointManageService: PointManageService,
    ) {
    }

    loaded(mapInstance: any) {
        mapInstance.enableScrollWheelZoom();
        const componentInstance = this;
        if (this.isCreate) {
            // @ts-ignore
            const locationCity = new BMap.LocalCity();
            locationCity.get(function (item) {
                if (item) {
                    mapInstance.centerAndZoom(item.center, 15);
                } else {
                    alert("没能获得正确地址");
                }
            });
        } else {
            // @ts-ignore
            mapInstance.centerAndZoom(new BMap.Point(this.data.longitude, this.data.latitude), 15);
        }
        // @ts-ignore
        const autocomplete = new BMap.Autocomplete({input: this.inputElement.nativeElement, location: mapInstance});
        let myValue;
        autocomplete.addEventListener("onconfirm", function (en) {
            // 鼠标点击下拉列表后的事件
            const _value = en.item.value;
            myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
            // componentInstance.addComp = _value;

            // @ts-ignore
            const local = new BMap.LocalSearch(mapInstance, { // 智能搜索
                onSearchComplete: function () {
                    const pp = local.getResults().getPoi(0).point;    // 获取第一个智能搜索的结果
                    mapInstance.centerAndZoom(pp, 15);
                    componentInstance.marker.point = pp;
                    // @ts-ignore
                    // const geoc = new BMap.Geocoder();
                    // geoc.getLocation(pp, function (rs) {
                    //     componentInstance.addComp = rs.addressComponents;
                    //     componentInstance.addComp.address = rs.surroundingPois[0].address;
                    // });
                    componentInstance.pointManageService.getMapDetail({latitude: pp.lat, longitude: pp.lng})
                        .subscribe(
                            (res) => {
                                componentInstance.addComp = res;
                                componentInstance.addComp.mapPointName = res["pois"][0]["name"];
                            },
                            (res) => {
                            },
                        );
                },
            });
            local.search(myValue);
        });
    }

    loadedMarker(markerInstance: any) {
        const componentInstance = this;
        markerInstance.enableDragging();
        if (this.isCreate) {
            // @ts-ignore
            const locationCity = new BMap.LocalCity();
            locationCity.get(function (item) {
                if (item) {
                    componentInstance.marker.point = item.center;
                } else {
                    alert("没能获得正确地址");
                }
            });
        } else {
            componentInstance.marker.point = {
                lng: this.data.longitude,
                lat: this.data.latitude,
            };
        }
        markerInstance.addEventListener("dragend", function (event) {
            componentInstance.marker.point = event.point;
            componentInstance.pointManageService.getMapDetail({latitude: event.point.lat, longitude: event.point.lng})
                .subscribe(
                    (res) => {
                        componentInstance.addComp = res;
                        componentInstance.addComp.mapPointName = res["pois"][0]["name"];
                    },
                    (res) => {
                    },
                );
            // @ts-ignore
            // const geoc = new BMap.Geocoder();
            // geoc.getLocation(event.point, function (rs) {
            //     componentInstance.addComp = rs.addressComponents;
            //     componentInstance.addComp.address = rs.surroundingPois[0].address;
            // });
        });
    }

    close() {
        this.modal.close({changed: false});
    }

    choose() {
        this.modal.close({changed: true, ...this.addComp, ...this.marker.point});
    }

    ngOnInit(): void {
    }

}
