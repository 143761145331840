import {Route} from "@angular/router";

export const orderHandleLogLogRoute: Route = {
    path: "order-handle-log",
    loadChildren: () => import("./order-handle-log.module").then(m => m.OrderHandleLogModule),
    data: {
        menuParent: "logs",
        menuLabel: "订单日志",
    },
};
