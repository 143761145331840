import {Route} from "@angular/router";

export const historyOrderRoute: Route = {
    path: "history-order",
    loadChildren: () => import("./history-order.module").then(m => m.HistoryOrderModule),
    data: {
        menuParent: "order",
        menuLabel: "历史订单",
    }
};
