import {Injectable} from "@angular/core";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {ModalOptions} from "ng-zorro-antd/modal/modal-types";
import {NzSafeAny} from "ng-zorro-antd/core/types";

declare module "ng-zorro-antd/modal/modal.service" {
    export interface NzModalService {
        createNoFooter<T>(config: ModalOptions<T>): NzModalRef<T>;
    }
}

@Injectable()
export class StandardModalService extends NzModalService {
    private defaultModalOptions: ModalOptions = {
        nzMaskClosable: false,
        nzWidth: 620,
    };

    create<T, R = NzSafeAny>(config: ModalOptions<T, R>): NzModalRef<T, R> {
        return super.create({
            ...this.defaultModalOptions,
            ...config,
        });
    }

    createNoFooter<T>(config: ModalOptions<T>): NzModalRef<T> {
        return super.create({
            ...this.defaultModalOptions,
            nzFooter: null,
            nzBodyStyle: {"padding": "0"},
            ...config,
        });
    }

    confirm<T>(config: ModalOptions<T>): NzModalRef<T> {
        return super.confirm({
            ...this.defaultModalOptions,
            ...config,
        });
    }

    info<T>(config: ModalOptions<T>): NzModalRef<T> {
        return super.info({
            ...this.defaultModalOptions,
            ...config,
        });
    }

    success<T>(config: ModalOptions<T>): NzModalRef<T> {
        return super.success({
            ...this.defaultModalOptions,
            ...config,
        });
    }

    error<T>(config: ModalOptions<T>): NzModalRef<T> {
        return super.error({
            ...this.defaultModalOptions,
            ...config,
        });
    }

    warning<T>(config: ModalOptions<T>): NzModalRef<T> {
        return super.warning({
            ...this.defaultModalOptions,
            ...config,
        });
    }
}
