import {Route} from "@angular/router";

export const machineModelRoute: Route = {
    path: "machine-model",
    loadChildren: () => import(/* webpackChunkName:"device-goods-module" */"./machine-model.module").then(m => m.MachineModelModule),
    data: {
        menuParent: "systemLibrary",
        menuLabel: "机型列表",
        requiredPrivilege: "machineModel:view"
    }
};
