import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {pagesRoute} from "@/pages/pages.route";
import {UserInfoService} from "@/global/user-info.service";
import {menuCategory} from "./menu-category";
import {UserBaseInfo} from "@/global/user-base-info";
import {NzModalService} from "ng-zorro-antd/modal";
import {Router} from "@angular/router";
import {Select, Store} from "@ngxs/store";
import {GlobalSelectedOrganization} from "@/global/store/global-selected-organization";
import {Observable} from "rxjs";
import {SelectedOrganization} from "@/global/store/selected-organization";
import {DownLoadListService} from "@/pages/down-load-list/down-load-list.service";
import {HttpResponse} from "@angular/common/http";
import {endOfToday, startOfDay, subDays} from "date-fns";
import {environment} from "@/../environments/environment";
import {PasswordModifyComponent} from "@/pages/public/main/password-modify/password-modify.component";

@Component({
    selector: "app-main",
    templateUrl: "./main.component.html",
    styleUrls: ["./main.component.less"],
})
export class MainComponent implements OnInit {
    private _collapsed: boolean;
    menu = [];
    userInfo: UserBaseInfo;
    dataLoading = false;
    downLoadParam = {
        pageNum: 1,
        pageSize: 5,
        startDate: subDays(startOfDay(new Date()), 7).getTime(),
        endDate: endOfToday().getTime(),
    };
    dataSet = [];
    dataCount = 0;
    @Select(GlobalSelectedOrganization.currentSelected) selectedStateInfo: Observable<SelectedOrganization>;

    @Input()
    get isCollapsed(): boolean {
        return this._collapsed;
    }

    set isCollapsed(val: boolean) {
        this._collapsed = val;
        localStorage.setItem("main-menu-collapsed", String(val));
    }

    @Input() language: "zh" | "en" = "zh";
    @Output() languageChange = new EventEmitter<"zh" | "en">();

    constructor(private modalService: NzModalService,
                private router: Router,
                private store: Store,
                private userInfoService: UserInfoService,
                private downLoadListService: DownLoadListService,
    ) {
        setTimeout(() => {
            this.isCollapsed = localStorage.getItem("main-menu-collapsed") === "true";
        });
    }

    onChangeLanguage(language: "en" | "zh"): void {
        this.languageChange.emit(language);
        this.language = language;
    }

    modifyPassword() {
        this.modalService
            .createNoFooter({
                nzTitle: "修改密码",
                nzContent: PasswordModifyComponent
            });
    }

    chooseOrganization() {
        this.router.navigate(["/customerList"]);
    }

    editOrganization() {
        this.router.navigate(["/organization-edit"]);
    }

    logout() {
        this.modalService.confirm({
            nzTitle: "退出登录？",
            nzContent: "确认要退出当前登录状态？",
            nzOnOk: () => {
                this.userInfoService
                    .logout()
                    .subscribe(() => {
                        window.location.reload();
                    });
            },
        });
    }

    goDown() {
        this.router.navigate(["/down-load-list"]);
    }

    loadData() {
        this.downLoadListService.query(this.downLoadParam)
            .subscribe(
                (res: HttpResponse<any>) => {
                    this.dataSet = res.body["excelList"];
                    this.dataCount = res.body["total"];
                },
                (res: HttpResponse<any>) => {
                },
            );
    }

    downloadById(id: String) {
        this.downLoadListService.downloadById(id).subscribe(
            () => {
                this.loadData();
            },
            () => {
            },
        );
    }


    reTryExportExcel(param: any) {
        const params: any = {};
        params.downloadId = param.id;
        this.downLoadListService.reTryExport(params).subscribe(
            () => {
                this.loadData();
            },
            () => {
            },
        );
    }

    ngOnInit() {
        this.userInfo = UserInfoService.userBaseInfo;
        const menu = JSON.parse(JSON.stringify(menuCategory));
        for (const page of pagesRoute) {
            if (page.data && page.data.menuLabel) {
                if (!page.data.requiredPrivilege || UserInfoService.hasPrivilege(page.data.requiredPrivilege)) {
                    if (!menu[page.data.menuParent]) {
                        page.data.menuParent = "other";
                    }
                    menu[page.data.menuParent].children.push({
                        label: page.data.menuLabel,
                        path: `/${page.path}`,
                    });
                }
            }
        }
        const menuArray = [];
        for (const key of Object.keys(menu)) {
            if (menu[key]) {
                menuArray.push(menu[key]);
            }
        }
        menuArray.sort((a, b) => {
            if (a.index && b.index) {
                return a.index - b.index;
            }
            return 0;
        });
        this.menu = menuArray;
    }

}
