import { Component, OnInit } from "@angular/core";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {RealTimeOrderService} from "@/pages/order/real-time-order/real-time-order.service";

@Component({
    selector: "app-service-details",
    templateUrl: "./service-details.component.html",
    styleUrls: ["./service-details.component.less"]
})
export class ServiceDetailsComponent implements OnInit {
    dataLoading = true;
    inputValue: string | null = null;
    orderData: any;
    orderType: string;
    dynamic: boolean;
    complaintInfo = {
        complaintReson: "",
        complaintType: "",
        handleType: "0",
        img1: null,
        img2: null,
        img3: null,
        results: "",
    };
    complainReasonMap = [
        "扣款错误",
        "商品过期",
        "其他"
    ];
    nullHandle = false;

    constructor(
        private message: NzMessageService,
        private modalService: NzModalService,
        private modal: NzModalRef,
        private realTimeOrderService: RealTimeOrderService
    ) { }

    sumbit() {
        if (this.complaintInfo.results === "") {
            this.message.warning("回复内容不能为空");
            return;
        }
        console.log(this.complaintInfo.results);
        this.realTimeOrderService.queryOrderComplaint({orderId: this.orderData.orderId, results: this.complaintInfo.results})
            .subscribe(
                res => {
                    if (res.result === 1) {
                        this.message.success("提交成功");
                        this.modal.close();
                    } else {
                        this.message.error("提交失败");
                    }
                },
                () => {

                }
            );
    }

    ngOnInit(): void {
        this.realTimeOrderService.selectOrderComplaint(this.orderData.orderId)
            .subscribe(
                res => {
                    this.complaintInfo = res;
                    this.dataLoading = false;
                },
                (res) => {
                    if (res.body.fault.message === "未进行客诉申请") {
                        this.nullHandle = true;
                    }
                    this.dataLoading = false;
                }
            );
    }

}
