export const menuCategory = {
    home: {
        label: "概览",
        icon: "line-chart",
        index: 1,
        children: [],
    },
    monitor: {
        label: "运营监控",
        icon: "eye",
        index: 3,
        children: [],
    },
    device: {
        label: "设备管理",
        icon: "desktop",
        index: 30,
        children: [],
    },
    setting: {
        label: "配置中心",
        icon: "setting",
        index: 35,
        children: [],
    },
    order: {
        label: "订单管理",
        icon: "ordered-list",
        index: 40,
        children: [],
    },
    goods: {
        label: "商品管理",
        icon: "gift",
        index: 50,
        children: [],
    },
    stock: {
        label: "库存管理",
        icon: "code-sandbox",
        index: 55,
        children: [],
    },
    linePoint: {
        label: "线路点位",
        icon: "node-index",
        index: 60,
        children: [],
    },
    payManage: {
        label: "支付管理",
        icon: "credit-card",
        index: 70,
        children: [],
    },
    organization: {
        label: "组织管理",
        icon: "fork",
        index: 80,
        children: [],
    },
    system: {
        label: "系统",
        icon: "setting",
        index: 100,
        children: [],
    },
    systemLibrary: {
        label: "公共库",
        icon: "appstore",
        index: 110,
        children: [],
    },
    logs: {
        label: "日志管理",
        icon: "file-text",
        index: 120,
        children: [],
    },
    apiTest: {
        label: "接口测试",
        icon: "api",
        index: 130,
        children: [],
    },
    dataBoard: {
        label: "数据看板",
        icon: "area-chart",
        index: 140,
        children: [],
    },
    other: {
        label: "其他",
        icon: "ungroup",
        index: 150,
        children: [],
    },
};
