import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ChoosePositionComponent} from "./choose-position.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BaiduMapModule} from "angular2-baidu-map";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzInputModule} from "ng-zorro-antd/input";
import {BMAP_API_AK} from "@/app.constants";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzButtonModule,
        NzInputModule,
        BaiduMapModule.forRoot({ak: BMAP_API_AK}),
    ],
    exports: [
        ChoosePositionComponent,
    ],
    declarations: [
        ChoosePositionComponent,
    ],
})
export class ChoosePositionModule {
}
