import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "./login.service";
import {UserInfoService} from "../user-info.service";
import {Router} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {Store} from "@ngxs/store";
import {GlobalSelectedOrganization, Update} from "@/global/store/global-selected-organization";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.less"],
})
export class LoginComponent implements OnInit {
    validateForm: FormGroup;
    loading = false;
    errMessage = "";
    state = "normal";

    constructor(private fb: FormBuilder,
                private loginService: LoginService,
                private router: Router,
                private store: Store,
                private message: NzMessageService,
                private privilegeService: UserInfoService) {
    }

    formChanged() {
        this.errMessage = "";
    }

    submitForm(): void {
        for (const i of Object.keys(this.validateForm.controls)) {
            this.validateForm.controls[i].markAsDirty();
            this.validateForm.controls[i].updateValueAndValidity();
        }
        if (this.validateForm.valid) {
            this.loading = true;
            this.loginService
                .login(this.validateForm.getRawValue())
                .subscribe(response => {
                        this.loading = false;
                        if (response.result === 1) {
                            if (this.validateForm.get("remember").value) {
                                localStorage.setItem("saved-login-name", this.validateForm.get("username").value);
                            } else {
                                localStorage.removeItem("saved-login-name");
                            }
                            this.store.dispatch(new Update([]));
                            this.privilegeService.jumpToLastUrl();
                            const passWordReg = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,}$/;
                        } else {
                            this.errMessage = response.fault.message;
                        }
                    },
                    (err: HttpErrorResponse) => {
                        this.loading = false;
                        this.errMessage = `${err.status} - ${err.statusText}`;
                    });
        }
    }

    ngOnInit(): void {
        this.validateForm = this.fb.group({
            username: [localStorage.getItem("saved-login-name"), [Validators.required]],
            password: [null, [Validators.required]],
            remember: [true],
        });
    }
}
