import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PayInfo} from "@/components/pay-info";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlipayPayInfoService} from "@/components/alipay-pay-info/alipay-pay-info.service";

@Component({
    selector: "svsp-submch-alipay-pay-info",
    templateUrl: "./sub-alipay.component.html",
    styleUrls: ["./sub-alipay.component.less"]
})
export class SubAlipayComponent implements OnInit {
    private _payInfo: PayInfo;
    validateForm: FormGroup;
    private validateTimer;
    private skipValidate = true;
    private _disabled = false;

    loading = true;
    providerList: any[];

    @Input()
    providerPayInfo: boolean;

    @Output()
    payInfoChange = new EventEmitter();

    @Input()
    get payInfo(): PayInfo {
        return this._payInfo;
    }

    @Output()
    payInfoValidChange = new EventEmitter();

    @Input()
    set disabled(value) {
        this._disabled = value;
        this.setFormDisableStatus();
    }

    private setFormDisableStatus() {
        if (this.validateForm) {
            for (const control of Object.values(this.validateForm.controls)) {
                if (this._disabled) {
                    control.disable();
                } else {
                    control.enable();
                }
            }
        }
    }

    set payInfo(payInfo) {
        if (payInfo) {
            this._payInfo = payInfo;
            if (this.validateForm) {
                this.validateForm.setValue({
                    alipaySubPid: this._payInfo.alipaySubPid || "",
                    alipayAppAuthToken: this._payInfo.alipayAppAuthToken || "",
                    alipayProviderId: this._payInfo.alipayProviderId || null
                });
            }
            if (!this.skipValidate) {
                this.payInfoChange.emit(this._payInfo);
            }
            this.skipValidate = false;
        }
    }

    formChanged() {
        this.payInfo = {...this.payInfo, ...this.validateForm.getRawValue()};
    }

    providerIdChange(value: number) {
        if (this.payInfo.alipayProviderId !== value) {
            this.formChanged();
        }
    }

    inputInvalid(formControlName: string) {
        clearTimeout(this.validateTimer);
        if (!this.skipValidate) {
            this.validateTimer = setTimeout(() => {
                this.payInfoValidChange.emit(this.validateForm.valid);
            }, 50);
        }
        return (this.validateForm.get(formControlName).dirty && this.validateForm.get(formControlName).errors);
    }

    constructor(private fb: FormBuilder, private service: AlipayPayInfoService) {
    }

    ngOnInit() {
        this.validateForm = this.fb.group({
            alipayProviderId: [null, [Validators.required]],
            alipaySubPid: [null, [Validators.required]],
            alipayAppAuthToken: [null, [Validators.required]],
        });
        this.setFormDisableStatus();
        this.providerPayInfo = this.providerPayInfo === true;
        this.payInfo = this._payInfo;
        this.service.getProviderList({payPlatform: "1"})
            .subscribe(res => {
                this.providerList = res.dataSet;
                this.loading = false;
            });
    }

}
