import {Route} from "@angular/router";

export const deviceStatusRoute: Route = {
    path: "device-status",
    loadChildren: () => import("./device-status.module").then(m => m.DeviceStatusModule),
    data: {
        menuParent: "monitor",
        menuLabel: "设备状态",
    }
};
