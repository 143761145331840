import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SERVER_API_PREFIX} from "@/app.constants";

@Injectable({
    providedIn: "root",
})
export class AlipayPayInfoService {

    constructor(private http: HttpClient) {
    }

    getProviderList(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/payInfo/provider/select/option`, {params});
    }
}
