import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {PayInfo} from "../pay-info";
import {TestPayInfoService} from "./test-pay-info.service";

@Component({
    selector: "svsp-test-pay-info",
    templateUrl: "./test-pay-info.component.html",
    styleUrls: ["./test-pay-info.component.less"]
})
export class TestPayInfoComponent implements OnInit {
    currentStep = 0;
    private defSteps = [
        {title: "下单", desc: "使用填写的支付信息创建订单", status: "process", icon: "qrcode"},
        {title: "扫码支付", desc: "使用对应的APP扫码支付该订单", status: "wait", icon: "scan"},
        {title: "退款", desc: "对支付完成的测试订单进行退款", status: "wait", icon: "pay-circle"}
    ];
    private orderId: string;
    steps = [];
    qrData = "";
    errMessage = "";

    @Output()
    successChange = new EventEmitter();

    @Input()
    payInfo: PayInfo;

    @Input()
    payPlatform: string;

    constructor(private testPayInfoService: TestPayInfoService) {
    }

    isSubPanyInfo() {
        if (this.payInfo.payPlatform === "1") {
            if (this.payInfo.alipayIsSubMch) {
                this.payInfo.isSub = "1";
            } else {
                this.payInfo.isSub = "0";
            }
        } else if (this.payInfo.payPlatform === "2") {
            if (this.payInfo.wechatIsSubMch) {
                this.payInfo.isSub = "1";
            } else {
                this.payInfo.isSub = "0";
            }
        }
    }

    createOrder() {
        this.isSubPanyInfo();
        this.startRequest();
        this.testPayInfoService
            .createOrder({
                payPlatform: this.payPlatform,
                ...this.payInfo
            })
            .subscribe(res => {
                if (res.result === 1 && res.success.success) {
                    this.qrData = res.success.qrCode;
                    this.orderId = res.success.orderId;
                    this.nextStep();
                } else {
                    this.handleError(res);
                }
            }, () => {
                this.handleError();
            });
    }

    queryOrder() {
        this.startRequest();
        this.testPayInfoService
            .queryOrder(this.orderId, {payPlatform: this.payPlatform, ...this.payInfo})
            .subscribe(res => {
                if (res.result === 1 && res.success.success) {
                    if (res.success.paymentStatus === "1") {
                        this.nextStep();
                    } else {
                        this.stopRequest(false);
                        this.handleError(res);
                    }
                } else {
                    this.handleError(res);
                }
            }, () => {
                this.handleError();
            });
    }

    refundOrder() {
        this.startRequest();
        this.testPayInfoService
            .refundOrder(this.orderId, {payPlatform: this.payPlatform, ...this.payInfo})
            .subscribe(res => {
                if (res.result === 1 && res.success.success) {
                    if (res.success.paymentStatus === "3") {
                        this.nextStep();
                        this.successChange.emit(true);
                    } else {
                        this.stopRequest(false);
                        this.handleError(res);
                    }
                } else {
                    this.handleError(res);
                }
            }, () => {
                this.handleError();
            });
    }

    private startRequest() {
        this.stopRequest(false);
        this.errMessage = "";
        this.steps[this.currentStep].icon = "loading";
    }

    private stopRequest(err: boolean) {
        this.steps[this.currentStep].icon = err ? "" : this.defSteps[this.currentStep].icon.valueOf();
        this.steps[this.currentStep].status = err ? "error" : "process";
    }

    private nextStep() {
        this.stopRequest(false);
        this.steps[this.currentStep].status = "finish";
        this.steps[this.currentStep].icon = "check";
        this.currentStep++;
        if (this.steps[this.currentStep]) {
            this.steps[this.currentStep].status = "process";
        }
    }

    private handleError(err?) {
        this.stopRequest(true);
        if (err) {
            this.errMessage = err["success"]["actionMsg"];
        }
    }

    resetStatus() {
        this.steps = JSON.parse(JSON.stringify(this.defSteps));
        this.orderId = null;
        this.qrData = "";
        this.errMessage = "";
        this.currentStep = 0;
        this.successChange.emit(false);
    }

    ngOnInit() {
        if (!/^[0-9]{1,2}$/.test(this.payPlatform)) {
            throw new Error(`组件的"payPlatform"必需设置一个值（两位的平台编码）`);
        }
        this.resetStatus();
    }

}
