import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SvspCollapseComponent} from "./svsp-collapse.component";

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        SvspCollapseComponent
    ],
    declarations: [
        SvspCollapseComponent
    ]
})
export class SvspCollapseModule {
}
