import {Route} from "@angular/router";

export const serviceProviderRoute: Route = {
    path: "service-provider",
    loadChildren: () => import("./service-provider.module").then(m => m.ServiceProviderModule),
    data: {
        menuParent: "payManage",
        menuLabel: "服务商管理",
    }
};
