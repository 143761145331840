/**
 * 支付信息
 */

export class PayInfo {
    payInfoId: string;
    companyId: string;
    orgCompanyName: string;
    payPlatform: string;
    payInfoName: string;
    createUserId: string;
    isSub: string;

    jdMerchantNo?: string;
    jdDesKey?: string;
    jdSaltMd5Key?: string;
    jdSystemId?: string;

    alipayIsSubMch?: boolean;
    alipayPid?: string;
    alipayAppId?: string;
    alipaySellerId?: string;
    alipayRsaPublicKey?: string;
    alipayRsaPrivateKey?: string;
    alipayProviderId?: number;
    alipaySubPid?: string;
    alipayAppAuthToken?: string;

    wechatIsSubMch?: boolean;
    wechatAppId?: string;
    wechatAppSecret?: string;
    wechatMchId?: string;
    wechatApiKey?: string;
    wechatV3Key?: string;
    wechatApiCertSerialNumber?: string;
    wechatApiCertExpireTimeStamp?: Date;
    wechatApiCertId?: string;
    wechatApiPrivateCertId?: string;
    wechatSubAppId?: string;
    wechatSubMchId?: string;
    wechatProviderId?: number;

    unionMerId?: string;
    unionCertPassword?: string;
    unionCert?: number;
    unionMiddleCert?: number;
    unionRootCert?: number;
    unionVerifySignCert?: number;
}
