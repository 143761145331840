import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {UserInfoService} from "@/global/user-info.service";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, switchMap} from "rxjs/operators";
import {UserBaseInfo} from "@/global/user-base-info";

@Component({
    selector: "app-password-modify",
    templateUrl: "./password-modify.component.html",
    styleUrls: ["./password-modify.component.less"],
})
export class PasswordModifyComponent implements OnInit {
    spinning = false;
    validateForm: FormGroup;
    requiredScore = 2;
    score = 0;
    progressStatus = {
        "0": "exception",
        "1": "exception",
        "2": "exception",
        "3": "normal",
        "4": "success",
    };
    userInfo: UserBaseInfo;

    private newPassText$ = new Subject<string>();

    constructor(private fb: FormBuilder,
                private messageService: NzMessageService,
                private modal: NzModalRef,
                private modalService: NzModalService,
                private userInfoService: UserInfoService,
    ) {
    }

    newPassChange(newVal: string) {
        if (newVal && newVal.length >= 6) {
            this.newPassText$.next(newVal);
        } else {
            this.score = 0;
            this.validateForm.get("newPassword").updateValueAndValidity();
            this.validateForm.get("confirmPassword").updateValueAndValidity();
        }
    }

    newPassValidator = (control: FormControl): { [s: string]: boolean } => {
        const passWordReg = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,}$/;
        if (!control.value) {
            this.score = 0;
            return {required: true};
        } else if (this.score < this.requiredScore || !passWordReg.test(control.value)) {
            return {tooWeak: true, error: true};
        }
    };

    confirmPassValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return {required: true};
        } else if (control.value !== this.validateForm.get("newPassword").value) {
            return {notEquals: true, error: true};
        }
    };

    close() {
        if (!this.validateForm.dirty) {
            this.modal.close({changed: false});
        } else {
            this.modalService.confirm({
                nzTitle: "放弃编辑？",
                nzContent: "确认要放弃修改，关闭该窗口？",
                nzOnOk: () => {
                    this.modal.close({changed: false});
                },
            });
        }
    }

    submit() {
        if (this.score < this.requiredScore) {
            this.messageService.warning("密码强度太低");
        } else {
            const params: any = {};
            params.userId = this.userInfo.userId;
            params.oldPassword = this.validateForm.get("currentPassword").value;
            params.newPassword = this.validateForm.get("newPassword").value;
            this.spinning = true;
            this.userInfoService
                .modifyPassword(params)
                .subscribe(() => {
                    this.messageService.success("密码修改成功！");
                    this.modal.close({changed: true});
                    this.logout();
                }, () => {
                    this.spinning = false;
                });
        }
    }

    logout() {
        this.userInfoService
            .logout()
            .subscribe(() => {
                window.location.reload();
            });
    }

    ngOnInit() {
        this.userInfo = UserInfoService.userBaseInfo;
        this.userInfoService
            .getMinimumPasswordScore()
            .subscribe(res => {
                this.requiredScore = res;
            });
        this.newPassText$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(password => this.userInfoService.getPasswordScore(password)),
        ).subscribe(resp => {
            this.score = resp;
            this.validateForm.get("newPassword").updateValueAndValidity();
            this.validateForm.get("confirmPassword").updateValueAndValidity();
        });
        this.validateForm = this.fb.group({
            currentPassword: [null, [Validators.required]],
            newPassword: [null, [Validators.required, this.newPassValidator]],
            confirmPassword: [null, [Validators.required, this.confirmPassValidator]],
        });
    }
}
