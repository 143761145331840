import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {PayInfo} from "../pay-info";
import {Observable, throwError} from "rxjs";
import {SERVER_API_PREFIX} from "@/app.constants";
import {NzMessageService} from "ng-zorro-antd/message";
import {catchError} from "rxjs/operators";
import {environment} from "../../../environments/environment";


@Injectable({
    providedIn: "root"
})
export class TestPayInfoService {

    constructor(private http: HttpClient, private message: NzMessageService) {
    }

    createOrder(req: PayInfo): Observable<any> {
        return this.http
            .skipErrorHandler()
            .skipResponseSimplify()
            .post(`${SERVER_API_PREFIX}/payInfo/test/create`, req)
            .pipe(catchError(err => this.handleError(err)));
    }

    queryOrder(orderId: string, payInfo: PayInfo): Observable<any> {
        return this.http
            .skipErrorHandler()
            .skipResponseSimplify()
            .post(`${SERVER_API_PREFIX}/payInfo/test/query/${orderId}`, payInfo)
            .pipe(catchError(err => this.handleError(err)));
    }

    refundOrder(orderId: string, payInfo: PayInfo): Observable<any> {
        return this.http
            .skipErrorHandler()
            .skipResponseSimplify()
            .post(`${SERVER_API_PREFIX}/payInfo/test/refund/${orderId}`, payInfo)
            .pipe(catchError(err => this.handleError(err)));
    }

    private handleError(err: HttpErrorResponse) {
        if (!environment.production) {
            console.log(err);
        }
        if (err.error && err.error.result === 0) {
            this.message.error(err.error.fault.message);
        } else {
            this.message.error(`${err.status} - ${err.statusText}`);
        }
        return throwError(err);
    }
}
