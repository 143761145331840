import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {OrderDetailComponent} from "@/pages/order/real-time-order/order-detail/order-detail.component";
import {NzImageModule} from "ng-zorro-antd/image";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ComponentsModule} from "@/components/components.module";
import {NzModalService} from "ng-zorro-antd/modal";
import {StandardModalService} from "@/global/standard-modal.service";
import {NzResultModule} from "ng-zorro-antd/result";
import {ServiceDetailsComponent} from "@/pages/order/real-time-order/service-details/service-details.component";

@NgModule({
    declarations: [
        OrderDetailComponent,
        ServiceDetailsComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ComponentsModule,
        NzImageModule,
        NzResultModule
    ],
    entryComponents: [
        OrderDetailComponent,
        ServiceDetailsComponent,
    ],
    providers: [
        {provide: NzModalService, useClass: StandardModalService},
    ],
})
export class OrderOperationModule {
}
