import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {SERVER_API_PREFIX} from "@/app.constants";

@Injectable({
    providedIn: "root",
})
export class PointManageService {
    constructor(private http: HttpClient) {
    }

    query(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/point/list`, {
            params,
        });
    }

    create(req: any): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/point/create`, req);
    }

    edit(req: any): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/point/edit`, req);
    }

    delItem(req: any): Observable<any> {
        return this.http.post(`${SERVER_API_PREFIX}/point/delete/batch`, req);
    }

    getPoint(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/point/deviceEdit/list`, {params});
    }

    getPointDetail(id: string): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/point/detail/${id}`);
    }

    getScene(): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/point/scene`);
    }

    getLine(): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/org/management/line`);
    }

    getMapDetail(params: any): Observable<any> {
        return this.http.get(`${SERVER_API_PREFIX}/point/info`, {params});
    }
}
