import {AlipayPayInfoModule} from "./alipay-pay-info/alipay-pay-info.module";
import {ChoosePositionModule} from "./choose-position/choose-position.module";
import {CommonModule} from "@angular/common";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {EncryptionTextPipe} from "./encryption-text/encryption-text.pipe";
import {FormatCodesPipe} from "./format-codes/format-codes.pipe";
import {HorizontalProportionBarModule} from "./horizontal-proportion-bar/horizontal-proportion-bar.module";
import {JdPayInfoModule} from "./jd-pay-info/jd-pay-info.module";
import {NgModule} from "@angular/core";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzCardModule} from "ng-zorro-antd/card";
import {NzCascaderModule} from "ng-zorro-antd/cascader";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzInputNumberModule} from "ng-zorro-antd/input-number";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzPopoverModule} from "ng-zorro-antd/popover";
import {NzProgressModule} from "ng-zorro-antd/progress";
import {NzRadioModule} from "ng-zorro-antd/radio";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzTabsModule} from "ng-zorro-antd/tabs";
import {NzTagModule} from "ng-zorro-antd/tag";
import {NzTreeSelectModule} from "ng-zorro-antd/tree-select";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzUploadModule} from "ng-zorro-antd/upload";
import {RequiresLoginUserOrgLevelDirective} from "./requires-login-user-org-level/requires-login-user-org-level.directive";
import {RequiresPermissionsDirective} from "./requires-permissions/requires-permissions.directive";
import {RequiresSelectOrgLevelDirective} from "./requires-select-org-level/requires-select-org-level.directive";
import {StandardTitleBarModule} from "./standard-title-bar/standard-title-bar.module";
import {SvspCollapseModule} from "./svsp-collapse/svsp-collapse.module";
import {TestPayInfoModule} from "./test-pay-info/test-pay-info.module";
import {UnionPayInfoModule} from "./union-pay-info/union-pay-info.module";
import {WechatPayInfoModule} from "./wechat-pay-info/wechat-pay-info.module";

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        AlipayPayInfoModule,
        ChoosePositionModule,
        DragDropModule,
        EncryptionTextPipe,
        FormatCodesPipe,
        HorizontalProportionBarModule,
        JdPayInfoModule,
        NzAlertModule,
        NzBadgeModule,
        NzBreadCrumbModule,
        NzButtonModule,
        NzCardModule,
        NzCascaderModule,
        NzCheckboxModule,
        NzDatePickerModule,
        NzDividerModule,
        NzDrawerModule,
        NzDropDownModule,
        NzFormModule,
        NzIconModule,
        NzInputModule,
        NzInputNumberModule,
        NzLayoutModule,
        NzMenuModule,
        NzModalModule,
        NzPopoverModule,
        NzProgressModule,
        NzRadioModule,
        NzSelectModule,
        NzSpinModule,
        NzSwitchModule,
        NzTableModule,
        NzTabsModule,
        NzTagModule,
        NzToolTipModule,
        NzTreeSelectModule,
        NzUploadModule,
        RequiresLoginUserOrgLevelDirective,
        RequiresPermissionsDirective,
        RequiresSelectOrgLevelDirective,
        StandardTitleBarModule,
        SvspCollapseModule,
        TestPayInfoModule,
        UnionPayInfoModule,
        WechatPayInfoModule,
    ],
    declarations: [
        EncryptionTextPipe,
        FormatCodesPipe,
        RequiresPermissionsDirective,
        RequiresSelectOrgLevelDirective,
        RequiresLoginUserOrgLevelDirective,
    ],
})
export class ComponentsModule {
}
