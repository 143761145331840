import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {NzModalService} from "ng-zorro-antd/modal";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MainComponent} from "./public/main/main.component";
import {AppRoutingModule} from "@/app-routing.module";
import {DashboardComponent} from "./public/dashboard/dashboard.component";
import {StandardModalService} from "@/global/standard-modal.service";
import {ComponentsModule} from "@/components/components.module";
import {PasswordModifyComponent} from "./public/main/password-modify/password-modify.component";
import {NgxEchartsModule} from "ngx-echarts";
import {GeoJSON} from "echarts/types/src/coord/geo/geoTypes";
import {OrderOperationModule} from "@/pages/order/order-operation.module";

let initMapPromise: Promise<void> = null;

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        ComponentsModule,
        OrderOperationModule,
        NgxEchartsModule.forRoot({
            echarts: () => {
                const promise = import("echarts");
                return promise.then(async echarts => {
                    if (initMapPromise === null) {
                        initMapPromise = new Promise<void>(async (resolve) => {
                            const country: GeoJSON = await fetch("assets/geo-data/100000.json",
                                {cache: "force-cache", credentials: "omit"})
                                .then(resp => resp.json());
                            echarts.registerMap("中国", country);
                            const provincePromises: Promise<any>[] = [];
                            for (const feature of country.features) {
                                if (feature.properties.name) {
                                    provincePromises.push(
                                        fetch(
                                            `assets/geo-data/${feature.properties.adcode}.json`,
                                            {cache: "force-cache", credentials: "omit"},
                                        ).then(resp => resp.json()).then((province: GeoJSON) => {
                                            echarts.registerMap(feature.properties.name, province);
                                        }),
                                    );
                                }
                            }
                            if (provincePromises.length > 0) {
                                await Promise.all(provincePromises);
                            }
                            resolve();
                        });
                    }
                    await initMapPromise;
                    return echarts;
                });
            },
        }),
    ],
    entryComponents: [PasswordModifyComponent],
    providers: [{provide: NzModalService, useClass: StandardModalService}],
    declarations: [MainComponent, DashboardComponent, PasswordModifyComponent],
})
export class PagesModule {
}

