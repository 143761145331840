import {Route} from "@angular/router";

export const machineLackOfGoodsStatusRoute: Route = {
    path: "machine-lack-of-goods-status",
    loadChildren: () => import("./machine-lack-of-goods-status.module").then(m => m.MachineLackOfGoodsStatusModule),
    data: {
        menuParent: "monitor",
        menuLabel: "机器空货",
    }
};
